import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadEnrollmentForm({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-Enrollment-form__1_.pdf"
          LinkCaption="DOWNLOAD ENROLLMENT FORM"
          ThumbnailImage="enrollment-form-thumb.png"
          className="enrollment-form-btn blue-button"
        >
          <h2 className="txt-uppercase">
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            HOME DELIVERY ENROLLMENT FORM
          </h2>
          <p>Easy enrollment for physicians and patients</p>
          <ol className="no-bold">
            <li>Patient completes top half of form.</li>
            <li>Prescriber signs preprinted prescription at bottom of form.</li>
            <li>Office faxes completed form to specialty pharmacy.</li>
          </ol>
          <p className="blue">
            E-prescribing is available &mdash; ask your Endo representative for
            details.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadEnrollmentForm;
