import React from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadPatientProfile({ className, ...props }) {
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-05581_Nascobal_Patient_Profile-Endocrinologist-Veeva_engage.pdf"
          LinkCaption="DOWNLOAD PATIENT PROFILE"
          ThumbnailImage="patient-profile-endocrinologist-thumb.png"
          className="ns-patient-profile-btn blue-button"
        >
          <h2 className="txt-uppercase">
            Patient Profile for Endocrinologists
          </h2>
          <p>
            Download to help quickly identify patients with vitamin B
            <sub className="vitaminb12-sub">12</sub> deficiency.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadPatientProfile;
