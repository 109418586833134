// Note for later - to get info about a wistia video, use the URL
// https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/xxxxxxxx
// Where "xxxxxxxx" is the video's wistia ID
const videoCollection = [
    {
      thumb: '',
      title: `NASCOBAL AND YOUR VITAMIN B12-DEFICIENT PATIENTS`,
      wistiaID: 'mmc2faxzf9',
      iframeUrl: '//fast.wistia.net/embed/iframe/jzbz9tj4os?videoFoam=true',
      alt: 'NASCOBAL AND YOUR VITAMIN B12-DEFICIENT PATIENTS',
    },
  ];
  
  export default videoCollection;
  