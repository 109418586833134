import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function VideoVitaminDeficientPatients({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          LinkCaption="WATCH VIDEO"
          ThumbnailImage="vitamin-deficient-video-thumb.png"
          VideoId="ns-vitamin-deficient-video"
          VideoLink
          className="btn-ns-deficient-video blue-button"
        >
          <h2 className="txt-uppercase">
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            AND YOUR VITAMIN B<sub className="vitaminb12-sub">12</sub>-DEFICIENT
            PATIENTS
          </h2>
          <p>
            Watch this video to learn about vitamin B
            <sub className="vitaminb12-sub">12</sub> deficiency and how{' '}
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            <br className="hide-mobile" />
            can help your appropriate patients.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default VideoVitaminDeficientPatients;
