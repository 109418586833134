import React, { useContext, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor from 'react-scrollable-anchor';

import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';

import ContentBlock from 'components/Content/ContentBlock';
import VideoContainer from 'components/VideoContainer';

import videoPoster from '../../../assets/images/vitamin-deficient-video-poster.png';
import DownloadEnrollmentForm from '../../../codes/components/callout-contents/nspat/DownloadEnrollmentForm';
import DownloadHCPBrochure from '../../../codes/components/callout-contents/nshcp/DownloadHCPBrochure';
import DownloadPatientProfile from '../../../codes/components/callout-contents/nspat/DownloadPatientProfile';
import VideoVitaminDeficientPatients from '../../../codes/components/callout-contents/nspat/VideoVitaminDeficientPatients';
import NascobalVideos from "../../../codes/libs/videos/nascobal-videos";
// import page style
import './practice-resources.scss';

import practiceResourcesHCPHeroMobile from '../../../assets/images/NS-HCP-Hero-PracticeResources-mobile.png';

const PracticeResourcesPage = () => {
  const { directLinkVideo, setDirectLinkVideo } = useState(false);
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  // const metaData = appConfigs?.metaData?.hcp;

  const pageTitle = 'NASCOBAL® | Practice Resources';
  const pageDescription = 'Download helpful resources for your practice';

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-practice-resources">
        <ContentBlock id="patient-resources-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>Practice Resources</h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={practiceResourcesHCPHeroMobile} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <DownloadEnrollmentForm />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <VideoVitaminDeficientPatients />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DownloadHCPBrochure />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DownloadPatientProfile />
              </Col>
            </Row>
          </Grid>
          <VideoContainer
            poster={videoPoster}
            url="https://fast.wistia.com/embed/medias/mmc2faxzf9"
            smallThumb
            carousel
            directLink={directLinkVideo === 'ndvideo1'}
            videoId="ns-vitamin-deficient-video"
            dlURL=""
            video={NascobalVideos[0]}

          />
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-practice-resources">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default PracticeResourcesPage;
