import React, { useContext } from 'react';
import { Callout } from 'components';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadHCPBrochure({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-05828_B12_Brochure.pdf"
          LinkCaption="DOWNLOAD BROCHURE"
          ThumbnailImage="vitamin-b12-brochure-thumb.png"
          className="hcp-brochure-btn blue-button"
        >
          <h2 className="txt-uppercase">
            Vitamin B<sub className="vitaminb12-sub">12</sub> Brochure
          </h2>
          <p>
            Download a brochure to learn more about{' '}
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            and the importance <br className="hide-mobile" />
            of recognizing vitamin B<sub className="vitaminb12-sub">
              12
            </sub>{' '}
            deficiency.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadHCPBrochure;
